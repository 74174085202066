* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 15px;
}
a {
  color: #484d62;
  text-decoration: none;
}
.svgIconS {
  fill: currentColor;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  /* margin: 30px; */
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 20px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #000;
  cursor: pointer;
}
